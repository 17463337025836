<template>
  <div class="customers-page">
    <Admins />
  </div>
</template>

<style lang="scss"></style>

<script>
import Admins from "@/views/admins/components/Admins.vue";
export default {
  name: "AdminsPage",
  components: { Admins }
};
</script>
