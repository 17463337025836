<template>
  <div
    class="shop-card"
    :class="{ unpublished: !shop.published || !shop.merchant_info.is_active }"
    v-if="shop"
  >
    <div class="shop-card-logo">
      <div class="shop-card-logo-container">
        <img v-if="shop.logo.square" :src="shop.logo.square" alt />
        <img v-if="!shop.logo.square" src="@/assets/client.jpg" alt />
      </div>
      <div class="shop-card-btn">
        <MerchantChangeForm :merchant="shop" />
        <MerchantDel :merchant="shop" />
      </div>
    </div>
    <div class="shop-card-content">
      <h2 class="shop-card-title">
        {{ shop.name }}
        <span
          class="unpublished"
          v-if="!shop.published || !shop.merchant_info.is_active"
        >Hors ligne</span>
      </h2>
      <div class="shop-card-text multiline-text" v-if="shop.description">{{ shop.description }}</div>
      <v-row>
        <v-col v-if="shop.phone">
          <div class="shop-card-text">
            <v-icon color="primary">call</v-icon>
            {{ shop.phone }}
          </div>
        </v-col>
        <v-col v-if="shop.email">
          <div class="shop-card-text">
            <v-icon color="primary">mail_outline</v-icon>
            {{ shop.email }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="shop.address">
          <div class="shop-card-text multiline-text">
            <v-icon color="primary">home_filled</v-icon>
            {{ shop.address }}
          </div>
        </v-col>
        <v-col v-if="shop.latitude">
          <div class="shop-card-text">
            <v-icon color="primary">location_on</v-icon>
            <a
              :href="'https://www.openstreetmap.org/?mlat=' + shop.latitude + '&mlon=' + shop.longitude + '&z=18'"
              target="_blank"
              title="ouvre une nouvelle fenêtre"
            >Géo-localiser</a>
            <small class="d-block">({{ shop.latitude }}, {{ shop.longitude }})</small>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="shop-card-text">
            <v-icon color="primary">face</v-icon>
            <strong v-if="!shop.merchant_info.first_name">{{ shop.merchant_info.username }}</strong>
            <strong v-if="shop.merchant_info.first_name">
              {{ shop.merchant_info.first_name }}
              {{ shop.merchant_info.last_name }}
            </strong>
            <template v-if="shop.merchant_info.email">({{ shop.merchant_info.email }})</template>
            <v-icon
              v-if="!shop.merchant_info.is_active"
              color="red"
              title="compte désactivé"
            >mdi-close-circle</v-icon>
            <v-icon
              v-if="shop.merchant_info.is_active"
              color="green"
              title="compte actif"
            >mdi-check-circle</v-icon>
          </div>
        </v-col>
      </v-row>
    </div>

    <div class="shop-card-category" v-if="shopCategory">{{ shopCategory.name }}</div>
    <img v-if="shop.photo.large" :src="shop.photo.large" alt class="shop-card-image" />
    <img
      v-if="!shop.photo.large && shopCategory.photo.large"
      :src="shopCategory.photo.large"
      alt
      class="shop-card-image"
    />
  </div>
</template>

<style lang="scss"></style>

<script>
import { useAppStore } from "@/stores/app-store";
import MerchantDel from "@/views/merchants/components/MerchantDel.vue";
import MerchantChangeForm from "@/views/merchants/components/MerchantChangeForm.vue";

export default {
  name: "Shop",
  props: {
    shop: {}
  },
  data: () => ({
    loading: true,
    shopCategory: null,
  }),
  beforeMount() {
    this.shopCategory = this.appStore.merchantCategories.filter((el) => el.id == this.shop.category);
    if (this.shopCategory.length > 0) {
      this.shopCategory = this.shopCategory[0];
    }
  },
  setup() {
    const appStore = useAppStore();
    return {
      appStore,
    };
  },
  components: { MerchantChangeForm, MerchantDel }
};
</script>