<template>
  <v-dialog v-model="dialog" max-width="600" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" plain small v-bind="attrs" v-on="on">
        <v-icon>edit</v-icon>
        <span class="sr-only">Modifier</span>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary" fixed>
        <v-btn icon dark @click.prevent="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Modifier le compte "{{ admin.first_name }} {{ admin.last_name }}"</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text :loading="loading" @click.prevent="save()">Enregistrer</v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-container>
        <v-form ref="adminChangeForm" lazy-validation>
          <div @keyup.enter="save">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Nom*"
                  v-model="data.last_name"
                  required
                  filled
                  :error-messages="errors.last_name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Prénom*"
                  v-model="data.first_name"
                  required
                  filled
                  :error-messages="errors.first_name"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Email*"
                  v-model="data.email"
                  type="email"
                  required
                  filled
                  :error-messages="errors.email"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-switch
                  v-model="data.is_active"
                  label="Activer ?"
                  :error-messages="errors.is_active"
                ></v-switch>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
</style>

<script>
import { AdminService } from "@/services/admin-services";
import { useAuthStore } from "@/stores/auth-store";
import { useAppStore } from "@/stores/app-store";

const adminService = new AdminService()

export default {
  name: "AdminChangeForm",
  props: {
    admin: Object
  },
  data: () => ({
    dialog: false,
    loading: false,
    data: {},
    errors: {},
  }),
  methods: {
    prefill() {
      this.data = {
        first_name: this.admin.first_name,
        last_name: this.admin.last_name,
        email: this.admin.email,
        is_active: this.admin.is_active
      }
    },
    close() {
      this.prefill()
      this.errors = {}
      this.dialog = false
    },
    async save() {
      this.errors = {}
      if (this.$refs.adminChangeForm.validate()) {
        this.loading = true
        try {
          const data = await adminService.update(this.admin.username, this.data, this.authStore.getToken)
          this.appStore.setRefresher('changeAdmin')
          this.$toast.success(`Le compte de "${data.first_name} ${data.last_name}" a bien été modifié`)
          this.$refs.adminChangeForm.resetValidation()
          this.dialog = false
        } catch (err) {
          this.errors = err
          if (err.non_field_errors) {
            for (const message of err.non_field_errors) {
              this.$toast.error(message)
            }
          } else {
            this.$toast.error('Votre formulaire contient des erreurs. Veuillez vérifier votre saisie')
          }
        } finally {
          this.loading = false
        }
      }
    },
  },
  beforeMount() {
    this.prefill()
  },
  setup() {
    const appStore = useAppStore();
    const authStore = useAuthStore();

    return {
      authStore,
      appStore
    };
  },
};
</script>