import { VueHttp } from "@/vue-http";

export class AuthenticationService {
    async logIn(email, password) {
        try {
            const res = await VueHttp.post("/admin/auth/login/", {
                email: email,
                password: password,
            });
            return res.data;
        } catch (error) {
            throw error.response.data;
        }
    }

    async sendEmail(email) {
        try {
            const res = await VueHttp.post("/auth/password/reset/", {
                email: email,
            });
            return res.data
        } catch (error) {
            throw error.response.data
        }
    }

    async logOut(token) {
        try {
            const mytoken = `token ${token}`;
            await VueHttp.post("auth/logout/", {
                headers: {
                    'Authorization': mytoken,
                }
            });
            sessionStorage.clear();
        } catch (error) {
            throw error.response.data;
        }
    }

    async changePassword(newPass, confPass, token) {
        try {
            const mytoken = `token ${token}`;
            const res = await VueHttp.post("auth/password/change/", {
                "new_password1": newPass,
                "new_password2": confPass
            }, {
                headers: {
                    'Authorization': mytoken,
                }
            });
            return res.data
        } catch (error) {
            throw error.response.data;
        }

    }

    async getUser(token) {
        try {
            const mytoken = `token ${token}`;
            const res = await VueHttp.get("auth/user/", {
                headers: {
                    'Authorization': mytoken,
                }
            });
            return res.data;
        } catch (error) {
            throw error.response.data;
        }
    }

    async checkUserIsLogged(token) {
        try {
            const mytoken = `token ${token}`;
            const res = await VueHttp.get("auth/is_logged/", {
                headers: {
                    'Authorization': mytoken,
                }
            });
            return res.data;
        } catch (error) {
            throw error.response.data;
        }
    }

    async updateUser(data, token) {
        const mytoken = `token ${token}`;

        let formData = new FormData();
        for (const key in data) {
            if (key !== 'id') {
                formData.append(key, data[key])
            }
        }

        try {
            const res = await VueHttp.patch(`/auth/user/`, formData, {
                headers: {
                    'Authorization': mytoken,
                }
            });
            return res.data
        } catch (error) {
            throw error.response.data
        }
    }

    async listEmails(token) {
        try {
            const mytoken = `token ${token}`;
            const res = await VueHttp.get("auth/email/", {
                headers: {
                    'Authorization': mytoken,
                }
            });
            return res.data;
        } catch (error) {
            throw error.response.data;
        }
    }

    async delEmail(email, token) {
        try {
            const mytoken = `token ${token}`;

            const res = await VueHttp.delete(`auth/email/delete/${email}/`, {
                headers: {
                    'Authorization': mytoken,
                }
            });
            return res.data
        } catch (error) {
            throw error.response.data;
        }
    }

    async addEmail(data, token) {
        const mytoken = `token ${token}`;

        let formData = new FormData();
        for (const key in data) {
            formData.append(key, data[key])
        }

        try {
            const res = await VueHttp.post(`/auth/email/add/`, formData, {
                headers: {
                    'Authorization': mytoken,
                }
            });
            return res.data
        } catch (error) {
            throw error.response.data
        }
    }

    async promoteEmail(email, token) {
        try {
            const mytoken = `token ${token}`;

            const res = await VueHttp.patch(`auth/email/primary/${email}/`, { email: email }, {
                headers: {
                    'Authorization': mytoken,
                }
            });
            return res.data
        } catch (error) {
            throw error.response.data;
        }
    }

    async addUser(data, token) {
        try {
            const mytoken = `token ${token}`;

            let formData = new FormData();
            for (const key in data) {
                if (data[key]) {
                    formData.append(key, data[key])
                }
            }

            const res = await VueHttp.post(`admin/auth/register/`, formData, {
                headers: {
                    'Authorization': mytoken,
                }
            });
            return res.data
        } catch (error) {
            throw error.response.data;
        }
    }
}


