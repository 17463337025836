export const appDayList = [
    { id: "1-sunday", text: "Dimanche" },
    { id: "2-monday", text: "Lundi" },
    { id: "3-tuesday", text: "Mardi" },
    { id: "4-wednesday", text: "Mercredi" },
    { id: "5-thursday", text: "Jeudi" },
    { id: "6-friday", text: "Vendredi" },
    { id: "7-saturday", text: "Samedi" }
]

export function dayFormat(day) {
    const formatDay = appDayList.filter((data) => data.id === day)[0]
    return formatDay.text
}

export function hourFormat(hour) {
    const el = hour.split(':')
    return `${el[0]}h${el[1]}`
}

export function formatPrice(price) {
    const newPrice = price / 100;
    return newPrice.toFixed(2)
}

export function getUrlParamsObject(url) {
    const proxyUrl = new URL(url)
    return new Proxy(new URLSearchParams(proxyUrl.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });
}