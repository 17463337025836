<template>
  <v-dialog v-model="dialog" max-width="600" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" plain small v-bind="attrs" v-on="on">
        <v-icon>edit</v-icon>
        <span class="sr-only">Modifier</span>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary" fixed>
        <v-btn icon dark @click.prevent="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Modifier la catégorie "{{ category.name }}"</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text :loading="loading" @click.prevent="save()">Enregistrer</v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-container>
        <v-form ref="changeForm" lazy-validation>
          <div @keyup.enter="save">
            <v-text-field
              v-model="data.name"
              label="Nom*"
              required
              filled
              :error-messages="errors.name"
            ></v-text-field>
            <v-file-input
              v-model="data.photo"
              label="Photo"
              :rules="photoRules"
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Sélectionnez une photo pour cette catégorie"
              prepend-icon="photo_camera"
              filled
              :error-messages="errors.photo"
            ></v-file-input>
            <v-switch v-model="data.published" label="Publié" :error-messages="errors.published"></v-switch>
          </div>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
</style>

<script>
import { MerchantService } from "@/services/merchant-services";
import { useAuthStore } from "@/stores/auth-store";
import { useAppStore } from "@/stores/app-store";

const merchantService = new MerchantService()

export default {
  name: "MerchantCategoryChangeForm",
  props: {
    merchant: Object,
    category: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    dialog: false,
    loading: false,
    data: {},
    errors: {},
    photoRules: [
      value => !value || value.size < 2000000 || 'Le logo doit peser moins de 2 Mo',
    ],
  }),
  methods: {
    prefill() {
      this.data = {
        name: this.category.name,
        published: this.category.published,
      }
    },
    close() {
      this.prefill()
      this.errors = {}
      this.dialog = false
    },
    async save() {
      this.errors = {}
      if (this.$refs.changeForm.validate()) {
        this.loading = true
        try {
          const data = await merchantService.updateCategory(this.category.id, this.data, this.authStore.getToken)
          this.appStore.merchantCategories = this.appStore.merchantCategories.map((cat) => {
            if (cat.id === data.id) return data
            return cat
          })
          this.$toast.success(`La categorie a bien été modifié`)
          this.$refs.changeForm.resetValidation()
          this.dialog = false
        } catch (err) {
          this.errors = err
          if (err.non_field_errors) {
            for (const message of err.non_field_errors) {
              this.$toast.error(message)
            }
          } else {
            this.$toast.error('Votre formulaire contient des erreurs. Veuillez vérifier votre saisie')
          }
        } finally {
          this.loading = false
        }
      }
    },
  },
  beforeMount() {
    this.prefill()
  },
  setup() {
    const appStore = useAppStore();
    const authStore = useAuthStore();

    return {
      authStore,
      appStore
    };
  },
};
</script>