<template>
    <v-dialog max-width="500px" v-model="dialog">
        <template v-slot:activator="{ on, attrs }">
            <tr v-bind="attrs" v-on="on">
                <th scope="row">{{ customer.username }}</th>
                <td>{{ customer.last_name }}</td>
                <td>{{ customer.first_name }}</td>
                <td>{{ customer.email }}</td>
                <td>
                    <v-icon v-if="customer.is_active" color="green">mdi-check-circle</v-icon>
                    <v-icon v-if="!customer.is_active" color="danger">mdi-close-circle</v-icon>
                </td>
            </tr>
        </template>
        <v-card>
            <v-card-title>{{ getTitle() }}</v-card-title>
            <v-card-text>
                <p
                    v-if="customer.is_active"
                    class="text-danger"
                >L'utilisateur sélectionné ne pourra plus se connecter à l'application</p>
                <p
                    v-if="!customer.is_active"
                    class="text-info"
                >L'utilisateur sélectionné pourra de nouveau se connecter à l'application</p>
                <v-icon color="primary">face</v-icon>
                <strong v-if="customer.first_name">
                    {{ customer.first_name }}
                    {{ customer.last_name }}
                </strong>
                ({{ customer.username }} - {{ customer.email }})
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" text @click="dialog = false">Non, revenir en arrière</v-btn>

                <v-btn
                    v-if="customer.is_active"
                    color="danger"
                    class="ml-auto"
                    text
                    :loading="loading"
                    @click="activate()"
                >Désactiver</v-btn>

                <v-btn
                    v-if="!customer.is_active"
                    color="success"
                    class="ml-auto"
                    text
                    :loading="loading"
                    @click="activate()"
                >Activer</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { DEBUG } from "@/main";
import { useAuthStore } from "@/stores/auth-store";
import { useAppStore } from "@/stores/app-store";
import { CustomerService } from "@/services/customer-services"

const customerService = new CustomerService()

export default {
    name: "Customer",
    props: {
        customer: Object
    },
    data: () => ({
        loading: false,
        dialog: false,
        merchant: {
            name: ''
        }
    }),
    methods: {
        getTitle() {
            return this.customer.is_active ? 'Êtes-vous sûr de vouloir désactiver ce compte ?' : 'Êtes-vous sûr de vouloir activer ce compte ?'
        },
        async activate() {
            this.loading = true
            try {
                await customerService.activate(this.customer.username, this.authStore.getToken)
                this.appStore.setRefresher('activateCustomer')
                if (this.customer.is_active) {
                    this.$toast.warning('Le compte a bien été désactivé')
                } else {
                    this.$toast.success('Le compte a bien été activé')
                }
                this.dialog = false
            } catch (err) {
                if (DEBUG) {
                    console.error(err)
                }
                this.$toast.error('La modification de l\'enregistrement a échoué. Si le problème persiste, contacter un administrateur')
            } finally {
                this.loading = false
            }
        }
    },
    setup() {
        const authStore = useAuthStore();
        const appStore = useAppStore();
        return {
            authStore,
            appStore
        };
    },
}
</script>