import VueRouter from 'vue-router'

import { AuthenticationService } from "@/services/auth-services";
import { useAuthStore } from '@/stores/auth-store';
import LoginPage from '@/views/login/LoginPage.vue'
import PrivateView from '@/views/PrivateView.vue'
import DashboardPage from '@/views/dashboard/DashboardPage.vue'
import MerchantsPage from '@/views/merchants/MerchantsPage.vue'
import ProfilePage from '@/views/profile/ProfilePage';
import CustomersPage from '@/views/customers/CustomersPage';
import AdminsPage from '@/views/admins/AdminsPage';


const authService = new AuthenticationService();

const routes = [
  {
    path: '/login/',
    name: 'login',
    component: LoginPage,
  },
  {
    path: '/',
    component: PrivateView,
    children: [
      {
        path: '',
        name: 'dashboard',
        component: DashboardPage,
      },
      {
        path: 'merchants/',
        name: 'merchants',
        component: MerchantsPage,
      },
      {
        path: 'customers/',
        name: 'customers',
        component: CustomersPage,
      },
      {
        path: 'admins/',
        name: 'admins',
        component: AdminsPage,
      },
      {
        path: 'profile/',
        name: 'profile',
        component: ProfilePage,
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.name != 'login') {
    const authStore = useAuthStore();
    try {
      const resp = await authService.checkUserIsLogged(authStore.getToken)
      if (resp.is_logged) {
        next()
      } else {
        next({ name: 'login' })
      }
    } catch (error) {
      next({ name: 'login' })
    }
  } else next()
})

export default router
