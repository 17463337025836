<template>
  <v-dialog v-model="dialog" max-width="600" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" plain small v-bind="attrs" v-on="on">
        <v-icon>edit</v-icon>
        <span class="sr-only">Modifier</span>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary" fixed>
        <v-btn icon dark @click.prevent="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Modifier le commerce "{{ merchant.name }}"</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text :loading="loading" @click.prevent="save()">Enregistrer</v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-container>
        <v-form ref="merchantChangeForm" lazy-validation>
          <div @keyup.enter="save">
            <h2>Information de la boutique</h2>
            <v-row>
              <v-col cols="12" md="4">
                <v-select
                  v-model="data.category"
                  :items="categories"
                  item-value="id"
                  item-text="name"
                  label="Catégorie"
                  filled
                  :error-messages="errors.category"
                  :append-icon="data.category ? 'close' : ''"
                  @click:append="data.category = ''"
                ></v-select>
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field
                  v-model="data.name"
                  label="Nom*"
                  required
                  filled
                  :error-messages="errors.name"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-file-input
                  v-model="data.logo"
                  label="Logo"
                  :rules="photoRules"
                  accept="image/png, image/jpeg, image/bmp"
                  placeholder="Sélectionnez le logo du commerce"
                  prepend-icon="photo_camera"
                  filled
                  :error-messages="errors.photo"
                ></v-file-input>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="Adresse*"
                  counter="200"
                  v-model="data.address"
                  auto-grow
                  required
                  filled
                  :error-messages="errors.address"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Telephone"
                  type="tel"
                  :error-messages="errors.phone"
                  v-model="data.phone"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Email"
                  type="email"
                  v-model="data.email"
                  :error-messages="errors.email"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Latitude"
                  type="number"
                  v-model="data.latitude"
                  :error-messages="errors.latitude"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Longitude"
                  type="number"
                  v-model="data.longitude"
                  :error-messages="errors.longitude"
                  filled
                ></v-text-field>
              </v-col>
            </v-row>

            <h2>Informations du commerçant</h2>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Nom*"
                  v-model="data.last_name"
                  required
                  filled
                  :error-messages="errors.last_name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Prénom*"
                  v-model="data.first_name"
                  required
                  filled
                  :error-messages="errors.first_name"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Email*"
                  v-model="data.merchant_email"
                  type="email"
                  required
                  filled
                  :error-messages="errors.merchant_email"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-switch
                  v-model="data.published"
                  label="Publié ?"
                  :error-messages="errors.published"
                ></v-switch>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">

</style>

<script>
import { MerchantService } from "@/services/merchant-services";
import { useAuthStore } from "@/stores/auth-store";
import { useAppStore } from "@/stores/app-store";

const merchantService = new MerchantService()

export default {
  name: "MerchantChangeForm",
  props: {
    merchant: Object
  },
  data: () => ({
    dialog: false,
    loading: false,
    categories: [],
    data: {},
    photoRules: [
      value => !value || value.size < 2000000 || 'Le logo doit peser moins de 2 Mo',
    ],
    errors: {},
  }),
  methods: {
    prefill() {
      this.data = {
        first_name: this.merchant.merchant_info.first_name,
        last_name: this.merchant.merchant_info.last_name,
        merchant_email: this.merchant.merchant_info.email,
        category: this.merchant.category ? this.merchant.category : '',
        name: this.merchant.name,
        logo: null,
        description: this.merchant.description,
        address: this.merchant.address,
        phone: this.merchant.phone,
        email: this.merchant.email,
        latitude: this.merchant.latitude,
        longitude: this.merchant.longitude,
        published: this.merchant.published
      }
    },
    close() {
      this.prefill()
      this.errors = {}
      this.dialog = false
    },
    async save() {
      this.errors = {}
      if (this.$refs.merchantChangeForm.validate()) {
        this.loading = true
        try {
          const data = await merchantService.update(this.merchant.id, this.data, this.authStore.getToken)
          this.appStore.setRefresher('changeMerchant')
          this.$toast.success(`Le commerce "${data.name}" a bien été modifié`)
          this.$refs.merchantChangeForm.resetValidation()
          this.dialog = false
        } catch (err) {
          this.errors = err
          if (err.non_field_errors) {
            for (const message of err.non_field_errors) {
              this.$toast.error(message)
            }
          } else {
            this.$toast.error('Votre formulaire contient des erreurs. Veuillez vérifier votre saisie')
          }
        } finally {
          this.loading = false
        }
      }
    },
  },
  beforeMount() {
    this.prefill()
    this.categories = this.appStore.merchantCategories
  },
  setup() {
    const appStore = useAppStore();
    const authStore = useAuthStore();

    return {
      authStore,
      appStore
    };
  },
};
</script>