<template>
  <header id="header">
    <div class="container-fluid">
      <nav role="navigation">
        <div class="header-brand">
          <div class="header-brand-logo">
            <img src="@/assets/client.jpg" alt />
          </div>
          <div class="header-brand-content">
            <div class="header-brand-title">Click N'Collect</div>
            <div class="header-brand-text">Espace d'administration</div>
          </div>

          <v-btn plain small class="ms-auto" v-if="collapseMenu" @click="toggleMenu = !toggleMenu">
            <v-icon>menu</v-icon>
            <span class="sr-only">Menu</span>
          </v-btn>
        </div>
        <div class="header-collapse" v-if="!collapseMenu || toggleMenu">
          <ul class="header-menu">
            <li>
              <a
                href="/"
                :class="{ active: isActive('dashboard') }"
                @click.prevent="navigate('dashboard')"
              >
                <v-icon>mdi-monitor-dashboard</v-icon>Tableau de bord
              </a>
            </li>
            <li>
              <a
                href="/merchants"
                :class="{ active: isActive('merchants') }"
                @click.prevent="navigate('merchants')"
              >
                <v-icon>storefront</v-icon>Commerçants
              </a>
            </li>
            <li>
              <a
                href="/customers"
                :class="{ active: isActive('customers') }"
                @click.prevent="navigate('customers')"
              >
                <v-icon>mdi-account-circle</v-icon>Clients
              </a>
            </li>
            <li>
              <a
                href="/admins"
                :class="{ active: isActive('admins') }"
                @click.prevent="navigate('admins')"
              >
                <v-icon>mdi-account-tie</v-icon>Administrateurs
              </a>
            </li>
          </ul>

          <a href="/profile" @click.prevent="navigate('profile')" class="header-nav-account">
            <span class="header-nav-account-icon">
              <v-icon>face</v-icon>
            </span>
            <span class="header-nav-account-text">
              Bienvenue
              <strong>{{ user.first_name }} {{ user.last_name }}</strong>
            </span>
          </a>

          <a href="#" role="button" @click.prevent="logOut" class="header-nav-action border-left">
            <v-icon color="primary">mdi-logout-variant</v-icon>
            <span class="ms-1" :class="{ 'sr-only': !collapseMenu }">Se déconnecter</span>
          </a>
        </div>
      </nav>
    </div>
  </header>
</template>

<style lang="scss"></style>


<script>
import { DEBUG } from "@/main";
import { AuthenticationService } from "@/services/auth-services";
import { useAuthStore } from "@/stores/auth-store";

const authService = new AuthenticationService();

export default {
  name: "Header",
  data: () => ({
    selectedMenu: "dashboard",
    user: {},
    toggleMenu: false,
  }),
  computed: {
    collapseMenu() {
      switch (this.$vuetify.breakpoint.name) {
        case "sm": return true;
        case "xs": return true;
      }
      return false;
    }
  },
  methods: {
    async logOut() {
      this.$toast.info("Déconnection en cours");
      try {
        await authService.logOut(this.authStore.getToken);
        this.authStore.clearAuth()
        this.$router.push({
          name: "login",
        });
      }
      catch {
        this.$toast.error("Une erreur s'est produite lors de votre déconnection. Veuillez contacter un administrateur.");
      }
    },
    isActive(routerName) {
      return routerName == this.selectedMenu ? true : false;
    },
    async navigate(routerName) {
      if (this.selectedMenu != routerName) {
        this.authStore.setSelectedMenu(routerName);
        this.authStore.pageLoading = true;
        try {
          await this.$router.push({
            name: routerName,
          });
        }
        catch (err) {
          if (DEBUG) {
            console.error(err);
          }
        }
        finally {
          this.authStore.pageLoading = false;
        }
      }
    }
  },
  beforeMount() {
    try {
      this.authStore.setSelectedMenu(this.$router.history.current.name);
    } catch (err) {
      if (DEBUG) console.error(err);
    }

    this.selectedMenu = this.authStore.getSelectedMenu;
    this.authStore.$subscribe((callback, state) => {
      this.selectedMenu = state.selectedMenu;
      this.user = state.user;
    });
  },
  setup() {
    const authStore = useAuthStore();
    return {
      authStore,
    };
  },
};
</script>