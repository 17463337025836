import { VueHttp } from "@/vue-http";

export class MerchantService {

  async show(token, options = null) {
    try {
      const mytoken = `token ${token}`;

      const res = await VueHttp.get(`admin/shop/`, {
        headers: {
          'Authorization': mytoken,
        },
        params: options
      });

      return res.data;
    } catch (error) {
      throw error.response.data
    }
  }

  async locations(token, options = null) {
    try {
      const mytoken = `token ${token}`;

      const res = await VueHttp.get(`admin/shop/locations/`, {
        headers: {
          'Authorization': mytoken,
        },
        params: options
      });

      return res.data;
    } catch (error) {
      throw error.response.data
    }
  }

  async add(data, token) {
    const mytoken = `token ${token}`;

    let formData = new FormData();
    for (const key in data) {
      if (data[key]) {
        formData.append(key, data[key])
      }
    }

    try {
      const res = await VueHttp.post(`admin/shop/add/`, formData, {
        headers: {
          'Authorization': mytoken,
          "Content-Type": "multipart/form-data",
        }
      });
      return res.data
    } catch (error) {
      throw error.response.data
    }
  }

  async del(merchantId, token) {
    try {
      const mytoken = `token ${token}`;

      const res = await VueHttp.delete(`admin/shop/${merchantId}/delete/`, {
        headers: {
          'Authorization': mytoken,
        }
      });
      return res.data
    } catch (error) {
      throw error.response.data;
    }
  }

  async update(merchantId, data, token) {
    const mytoken = `token ${token}`;

    let formData = new FormData();
    for (const key in data) {
      if (['logo', 'latitude', 'longitude'].includes(key)) {
        if (data[key]) formData.append(key, data[key])
      } else {
        formData.append(key, data[key])
      }
    }

    try {
      const res = await VueHttp.patch(`admin/shop/${merchantId}/`, formData, {
        headers: {
          'Authorization': mytoken,
          "Content-Type": "multipart/form-data",
        }
      });
      return res.data
    } catch (error) {
      throw error.response.data
    }
  }

  async get(merchantId, token) {
    const mytoken = `token ${token}`;

    try {
      const res = await VueHttp.get(`/me-merchant/${merchantId}/`, {
        headers: {
          'Authorization': mytoken,
        }
      });
      return res.data
    } catch (error) {
      throw error.response.data
    }
  }

  async categories(token) {
    try {
      const mytoken = `token ${token}`;
      const res = await VueHttp.get(`admin/category/`, {
        headers: {
          'Authorization': mytoken,
        }
      });
      return res.data.results;
    } catch (error) {
      throw error.response.data
    }
  }

  async addCategory(data, token) {
    const mytoken = `token ${token}`;

    let formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key])
    }

    try {
      const res = await VueHttp.post(`admin/category/`, formData, {
        headers: {
          'Authorization': mytoken,
          "Content-Type": "multipart/form-data",
        }
      });
      return res.data
    } catch (error) {
      throw error.response.data
    }
  }

  async delCategory(categoryId, token) {
    try {
      const mytoken = `token ${token}`;

      const res = await VueHttp.delete(`admin/category/${categoryId}/`, {
        headers: {
          'Authorization': mytoken,
        }
      });
      return res.data
    } catch (error) {
      throw error.response.data;
    }
  }

  async updateCategory(categoryId, data, token) {
    const mytoken = `token ${token}`;

    let formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key])
    }

    try {
      const res = await VueHttp.patch(`admin/category/${categoryId}/`, formData, {
        headers: {
          'Authorization': mytoken,
          "Content-Type": "multipart/form-data",
        }
      });
      return res.data
    } catch (error) {
      throw error.response.data
    }
  }
}