<template>
  <div class="customers-page">
    <Customers />
  </div>
</template>

<style lang="scss"></style>

<script>
import Customers from "@/views/customers/components/Customers.vue";

export default {
  name: "CustomersPage",
  components: { Customers }
};
</script>
