<template>
  <div class="merchant-list">
    <Shop v-for="merchant in merchants" :shop="merchant" :key="merchant.id" />
  </div>
</template>

<style lang="scss"></style>

<script>
import { useAppStore } from "@/stores/app-store";
import { formatPrice } from "@/utils";
import Shop from "@/views/merchants/components/Shop.vue";

export default {
  name: "MerchantList",
  props: {
    merchants: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      getMerchantOptions: {},
    };
  },
  methods: {
    formatPrice(price) {
      return formatPrice(price);
    },
    getCategoryName(categoryId) {
      const categorie = this.appStore.merchantCategories.filter((cat) => cat.id === categoryId)
      if (categorie.length > 0) {
        return categorie[0].name
      }
      return ''
    }
  },
  setup() {
    const appStore = useAppStore();
    return {
      appStore
    };
  },
  components: { Shop }
};
</script>



