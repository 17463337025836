<template>
  <v-dialog v-model="dialog" max-width="600" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on">
        <v-icon>mail_outline</v-icon>Ajouter un mail
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary" fixed>
        <v-btn icon dark @click.prevent="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Ajouter un mail</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text :loading="loading" @click.prevent="save()">Ajouter</v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-container>
        <v-form ref="addForm" lazy-validation>
          <div @keyup.enter="save">
            <v-text-field
              v-model="data.email"
              label="Email*"
              required
              filled
              type="email"
              :error-messages="errors.email"
            ></v-text-field>
            <v-text-field class="sr-only"></v-text-field>
          </div>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<style lang="scss"></style>

<script>
import { AuthenticationService } from "@/services/auth-services";
import { useAuthStore } from "@/stores/auth-store";

const authService = new AuthenticationService

export default {
  name: "ProfileEmailAddForm",
  data: () => ({
    dialog: false,
    loading: false,
    data: {},
    errors: {},
  }),
  methods: {
    prefill() {
      const initial = {
        email: '',
      }
      this.data = initial
    },
    close() {
      this.$refs.addForm.resetValidation()
      this.prefill()
      this.errors = {}
      this.dialog = false
    },
    async save() {
      this.errors = {}
      if (this.$refs.addForm.validate()) {
        this.loading = true
        try {
          const data = await authService.addEmail(this.data, this.authStore.getToken)
          this.authStore.emails.results.unshift(data)
          this.authStore.emails.count += 1
          this.$toast.warning(`Un email de validation vous sera envoyé à l'adresse "${data.email}" pour finaliser l'ajout.`)
          this.close()
        } catch (err) {
          this.errors = err
          if (err.non_field_errors) {
            for (const message of err.non_field_errors) {
              this.$toast.error(message)
            }
          } else {
            this.$toast.error('Votre formulaire contient des erreurs. Veuillez vérifier votre saisie')
          }
        } finally {
          this.loading = false
        }
      }
    },
  },
  beforeMount() {
    this.prefill()
  },
  setup() {
    const authStore = useAuthStore();

    return {
      authStore,
    };
  },
};
</script>