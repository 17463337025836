<template>
  <footer id="footer">
    <div class="container">
      <p>
        Click'n'Collect
        <a
          href="https://www.ville-sainte-anne.fr"
          title="Ouvre une nouvelle fenêtre"
          target="_blank"
        >Ville de Sainte-Anne</a>
        | Propulsé par
        <a
          href="https://www.i-administration.fr"
          title="Ouvre une nouvelle fenêtre"
          target="_blank"
        >
          <img src="@/assets/iadministration.jpg" class="logo-footer-text" alt />
          I-Administration
        </a>
      </p>
    </div>
  </footer>
</template>

<style lang="scss"></style>

<script>
export default {
  name: "Footer",
};
</script>