<template>
  <div class="profile-emails">
    <div class="shop-card">
      <div class="shop-card-container">
        <div class="categorie-title">
          <h2>
            Emails associés
            <small v-if="emails.count > 0">({{ emails.count }})</small>
          </h2>
          <ProfileEmailAddForm v-if="emails.length > 25" />
        </div>

        <ul class="categorie-list">
          <li class="categorie-list-item" v-for="email in emails.results" :key="email.email">
            <div class="categorie-list-item-text">
              {{ email.email }}
              <v-badge :color="email.verified ? 'success' : 'danger'" inline></v-badge>
              <span class="main" v-if="email.primary">Principale</span>
            </div>
            <div class="categorie-list-item-action">
              <ProfileEmailPrimary :email="email" v-if="!email.primary && email.verified" />
              <ProfileEmailDel :email="email" v-if="!email.primary" />
            </div>
          </li>
        </ul>
        <ProfileEmailAddForm />
      </div>
    </div>
  </div>
</template>

<style lang="scss"></style>

<script>
import { useAuthStore } from "@/stores/auth-store";
import { AuthenticationService } from "@/services/auth-services";
import ProfileEmailDel from "@/views/profile/components/ProfileEmailDel.vue";
import ProfileEmailAddForm from "@/views/profile/components/ProfileEmailAddForm.vue";
import ProfileEmailPrimary from "@/views/profile/components/ProfileEmailPrimary.vue";
import { DEBUG } from "@/main";

const authService = new AuthenticationService()

export default {
  name: "ProfileEmails",
  data: () => ({
    emails: []
  }),
  async beforeMount() {
    this.authStore.$subscribe((callback, state) => {
      this.emails = state.emails
    })

    try {
      this.authStore.emails = await authService.listEmails(this.authStore.token)
    } catch (err) {
      if (DEBUG) {
        console.error(err)
      }
      this.$toast.error('Impossible de récupérer la liste des emails')
    }
  },
  setup() {
    const authStore = useAuthStore()

    return {
      authStore,
    }
  },
  components: { ProfileEmailDel, ProfileEmailAddForm, ProfileEmailPrimary }
};
</script>



