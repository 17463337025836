<template>
    <div class="customers">
        <div class="customers-title">
            <h1>
                Comptes clients
                <small>{{ customerCounter }} résultats</small>
            </h1>

            <div class="customer-filters">
                <div @keyup.enter="refreshCustomers()">
                    <v-text-field
                        v-model="getCustomerOptions.search"
                        label="Recherche"
                        outlined
                        clearable
                        append-icon="search"
                        @click:clear="() => { getCustomerOptions.search = ''; refreshCustomers() }"
                        @click:append="refreshCustomers()"
                        hide-details
                        single-line
                    ></v-text-field>
                </div>
                <v-select
                    v-model="getCustomerOptions.ordering"
                    :items="customerOrdering"
                    item-value="id"
                    item-text="name"
                    menu-props="auto"
                    label="Ordonné par"
                    outlined
                    clearable
                    hide-details
                    single-line
                    @change="refreshCustomers()"
                ></v-select>
            </div>
        </div>
        <div class="shop-card">
            <div class="shop-card-container text-center" v-if="!loading && !customers.length">
                <div class="shop-card-text">
                    <div class="mb-2">
                        <v-icon color="primary" x-large>mdi-account-circle</v-icon>
                    </div>
                    <strong>Aucun compte client créé sur l'application pour le moment</strong>
                </div>
            </div>
            <div class="shop-card-container" v-if="!loading && customers.length">
                <CustomerList :customers="customers" />
            </div>
        </div>
    </div>
</template>

<script>
import { DEBUG } from "@/main";
import { CustomerService } from "@/services/customer-services";
import { useAuthStore } from "@/stores/auth-store";
import { useAppStore } from "@/stores/app-store";
import { getUrlParamsObject } from "@/utils";
import CustomerList from "@/views/customers/components/CustomerList.vue";

const customerService = new CustomerService()

export default {
    name: "Customers",
    props: {
        limit: Number,
        filterOptions: {
            type: Object,
            default: () => ({
                ordering: "-is_active",
                page: 1
            })
        },
    },
    data: () => ({
        loading: true,
        customers: [],
        customerCounter: 0,
        getCustomerOptions: {},
        nextPage: false,
        customerOrdering: [
            { id: '-is_active', name: 'Par compte actif' },
            { id: 'last_name', name: 'Par nom (A-Z)' },
            { id: 'first_name', name: 'Par prénom (A-Z)' },
        ]
    }),
    methods: {
        async getCustomers() {
            try {
                const customers = await customerService.show(this.authStore.getToken, this.getCustomerOptions);
                if (this.limit) {
                    return customers.results.slice(0, this.limit);
                }
                this.customerCounter = customers.count;
                if (customers.next) {
                    const params = getUrlParamsObject(customers.next);
                    this.getProductOptions.page = params.page;
                    this.nextPage = true;
                }
                else {
                    this.nextPage = false;
                }
                return customers.results;
            }
            catch (error) {
                console.error(error);
                this.$toast.error("Une erreur s'est produite lors de la récupération de la liste des clients");
            }
            finally {
                this.loading = false;
            }
            return [];
        },
        refreshCustomers() {
            this.getCustomerOptions.page = 1;
            this.getCustomers().then(customers => this.customers = customers);
        },
        infiniteScroll(entries) {
            if (entries[0].isIntersecting && this.nextPage) {
                this.getCustomers().then(customers => customers.forEach((o) => this.customers.push(o)));
            }
        },
    },
    async beforeMount() {
        this.getCustomerOptions = this.filterOptions;
        try {
            await this.refreshCustomers();
        }
        catch (err) {
            if (DEBUG) {
                console.error(err);
            }
        }
        finally {
            this.loading = false;
        }
        this.appStore.$subscribe(() => {
            this.refreshCustomers();
            this.loading = false;
        });
    },
    setup() {
        const authStore = useAuthStore();
        const appStore = useAppStore();
        return {
            authStore,
            appStore
        };
    },
    components: { CustomerList }
}
</script>