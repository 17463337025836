<template>
  <div class="shop-card-stat-number">
    <div class="card-num">
      <number
        ref="number1"
        :from="0"
        :to="stats"
        :format="theFormat"
        :duration="2"
        :delay="1"
        easing="Power1.easeOut"
      />
      <div class="icon">
        <v-icon large :color="iconColor">{{ icon }}</v-icon>
      </div>
    </div>
    <div class="card-text">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: "CardStat",
  props: {
    title: String,
    stats: Number,
    precision: {
      type: Number,
      default: 0
    },
    icon: String,
    iconColor: {
      type: String,
      default: '#8f00d2'
    },
  },
  methods: {
    theFormat(number) {
      return number.toFixed(this.precision);
    },
    completed() {
      console.log("Animation ends!");
    },
    playAnimation() {
      this.$refs.number2.play();
    },
  },
};
</script>

<style lang="scss">
$breakpoints: (
  "phone-down": 500px,
  "tablet-up": 768px,
  "tablet-down": 900px,
  "desktop-up": 1024px,
  "desktop-down": 1280px,
  "widescreen-up": 1440px
);
@mixin media-max($_key) {
  @media screen and (max-width: map-get($breakpoints, $_key)) {
    & {
      @content;
    }
  }
}

@mixin media-min($_key) {
  @media screen and (min-width: map-get($breakpoints, $_key)) {
    & {
      @content;
    }
  }
}
.lopez {
  padding: 1%;
  justify-content: space-between;
}

.v-card__title {
  @include media-max("phone-down") {
    font-size: 1rem !important;
  }
}

.num {
  @include media-max("tablet-up") {
    font-size: 1em;
  }
  @include media-max("phone-down") {
    font-size: 0.5em;
  }
}
</style>