<template>
  <div class="merchants-page">
    <Merchants />
  </div>
</template>

<style lang="scss"></style>

<script>
import Merchants from "@/views/merchants/components/Merchants.vue";

export default {
  name: "MerchantsPage",
  components: {
    Merchants,
  },
};
</script>
