<template>
  <div class="customer-list">
    <div class="customer-tab-responsive">
      <table class="customer-tab">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Nom</th>
            <th scope="col">Prénom</th>
            <th scope="col">Email</th>
            <th scope="col">Actif ?</th>
          </tr>
        </thead>
        <tbody>
          <Customer
            :customer="customer"
            v-for="customer, i in customers"
            :key="'customer-' + i + '-' + customer.username"
          />
        </tbody>
      </table>
    </div>
  </div>
</template>

<style lang="scss"></style>

<script>
import Customer from "@/views/customers/components/Customer.vue";

export default {
  name: "CustomerList",
  props: {
    customers: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      getOptions: {},
    };
  },
  components: { Customer }
};
</script>



