import { defineStore } from 'pinia'


export const useAppStore = defineStore('appStore', {
    state: () => {
        return {
            merchantCategories: [],
            // utilisé pour forcer une resouscription au store
            // pratique pour les modifications de produit ou de facture
            refresher: null
        }
    },
    actions: {
        setRefresher(value) {
            const nowTime = new Date().getTime()
            this.refresher = `${value}-${nowTime}`
        }
    },

})