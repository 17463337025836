import { defineStore } from 'pinia'
import CryptoJS from 'crypto-js';

const SECRET_KEY = 'hoo9eiBoh9Kaetaeng2ii1iethoh7queu1Ekahy1oY';

export const useAuthStore = defineStore('authStore', {

  state: () => {
    return {
      token: '',
      user: {},
      pageLoading: false,
      selectedMenu: undefined,
      emails: []
    }
  },
  actions: {
    setToken(token) {
      this.token = token;
      const encryptedToken = CryptoJS.AES.encrypt(this.token, SECRET_KEY).toString();
      sessionStorage.setItem('token', JSON.stringify(encryptedToken));
    },
    clearAuth() {
      this.token = ''
      this.user = {}
      this.pageLoading = false
      this.selectedMenu = undefined,
        this.emails = []
      sessionStorage.clear()
    },
    setSelectedMenu(menu) {
      this.selectedMenu = menu;
      sessionStorage.setItem('selectedMenu', menu);
    }
  },
  getters: {
    getToken: (state) => {
      if (!state.token.length > 0) {
        const encryptedToken = JSON.parse(sessionStorage.getItem('token'));
        const token = CryptoJS.AES.decrypt(encryptedToken, SECRET_KEY);
        state.token = token.toString(CryptoJS.enc.Utf8)
      }
      return state.token;
    },
    getSelectedMenu: (state) => {
      if (state.selectedMenu === undefined) {
        state.selectedMenu = sessionStorage.getItem('selectedMenu');
        if (!state.selectedMenu) {
          state.selectedMenu = 'dashboard'
        }
      }
      return state.selectedMenu;
    },
  }
})