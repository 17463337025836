<template>
    <v-dialog max-width="500px" v-model="dialog">
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="danger" plain small v-bind="attrs" v-on="on">
                <v-icon>delete_outline</v-icon>
                <span class="sr-only">Supprimer</span>
            </v-btn>
        </template>
        <v-card>
            <v-card-title>Êtes-vous sûr de vouloir supprimer le commerce "{{ merchant.name }}" ?</v-card-title>
            <v-card-text>Attention : cette action est irréversible. La boutique ne sera plus disponible et le compte de gestion sera supprimé.</v-card-text>
            <v-card-actions>
                <v-btn color="primary" text @click="dialog = false">Non, revenir en arrière</v-btn>

                <v-btn
                    color="danger"
                    class="ml-auto"
                    text
                    :loading="loading"
                    @click="remove()"
                >Supprimer</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { DEBUG } from "@/main";
import { MerchantService } from "@/services/merchant-services";
import { useAuthStore } from "@/stores/auth-store";
import { useAppStore } from "@/stores/app-store";

const merchantService = new MerchantService();

export default {
    name: "MerchantDel",
    props: {
        merchant: Object
    },
    data: () => ({
        loading: false,
        dialog: false
    }),
    methods: {
        async remove() {
            this.loading = true
            try {
                await merchantService.del(this.merchant.id, this.authStore.getToken)
                this.appStore.setRefresher('delMerchant')
                this.$toast.success('Le commerce et le compte de gestion associé ont été supprimés')
                this.dialog = false
            } catch (err) {
                if (DEBUG) {
                    console.error(err)
                }
                this.$toast.error('La suppression de l\'enregistrement a échoué. Si le problème persiste, contacter un administrateur')
            } finally {
                this.loading = false
            }
        }
    },
    setup() {
        const authStore = useAuthStore();
        const appStore = useAppStore();
        return {
            authStore,
            appStore
        };
    },
}
</script>