<template>
  <div class="merchants">
    <div class="merchants-title">
      <component :is="'h' + headingLevel">
        {{ title }}
        <small v-if="!modeWidget">{{ merchantCounter }} résultats</small>
      </component>

      <div class="merchant-filters" v-if="showFilter">
        <v-select
          v-if="merchantCategoriesUsed.length > 0"
          v-model="getMerchantOptions.category"
          :items="merchantCategoriesUsed"
          item-value="id"
          item-text="name"
          menu-props="auto"
          label="Filtrer par catégorie"
          outlined
          clearable
          hide-details
          single-line
          @change="refreshMerchants()"
        ></v-select>
        <div @keyup.enter="refreshMerchants()">
          <v-text-field
            v-model="getMerchantOptions.search"
            label="Recherche"
            outlined
            clearable
            append-icon="search"
            @click:clear="() => { getMerchantOptions.search = ''; refreshMerchants() }"
            @click:append="refreshMerchants()"
            hide-details
            single-line
          ></v-text-field>
        </div>
        <v-select
          v-model="getMerchantOptions.ordering"
          :items="merchantOrdering"
          item-value="id"
          item-text="name"
          menu-props="auto"
          label="Ordonné par"
          outlined
          clearable
          hide-details
          single-line
          @change="refreshMerchants()"
        ></v-select>
      </div>
      <div class="ml-auto">
        <MerchantAddForm v-if="!loading && merchants.length" />
      </div>
    </div>
    <v-row v-if="!modeWidget">
      <v-col cols="12" md="4">
        <MerchantCategories :categories="merchantCategories" />
      </v-col>
      <v-col cols="12" md="8">
        <div class="shop-card" v-if="!loading && !merchants.length">
          <div class="shop-card-container text-center">
            <div class="shop-card-text">
              <div class="mb-2">
                <v-icon color="primary" x-large>merchantion_quantity_limits</v-icon>
              </div>
              <strong>Vous n'avez ajouté aucun produit</strong>
            </div>
            <MerchantAddForm />
          </div>
        </div>
        <MerchantList :merchants="merchants" v-if="!loading && merchants.length" />
        <div v-intersect="infiniteScroll"></div>
      </v-col>
    </v-row>

    <template v-if="modeWidget">
      <div class="shop-card" v-if="!loading && !merchants.length">
        <div class="shop-card-container text-center">
          <div class="shop-card-text">
            <div class="mb-2">
              <v-icon color="primary" x-large>storefront</v-icon>
            </div>
            <strong>Vous n'avez ajouté aucun commerce</strong>
          </div>
          <MerchantAddForm />
        </div>
      </div>
      <MerchantList :merchants="merchants" v-if="!loading && merchants.length" />
    </template>

    <div class="text-right my-5" v-if="modeWidget && merchants.length > 0">
      <v-btn color="primary" @click="navigate('merchants')">Plus de produits</v-btn>
    </div>
  </div>
</template>

<style lang="scss"></style>

<script>
import { useAuthStore } from "@/stores/auth-store";
import { useAppStore } from "@/stores/app-store";
import { MerchantService } from "@/services/merchant-services";
import { getUrlParamsObject } from "@/utils";
import MerchantAddForm from "@/views/merchants/components/MerchantAddForm.vue";
import MerchantList from "@/views/merchants/components/MerchantList.vue";
import MerchantCategories from "@/views/merchants/components/MerchantCategories.vue";
import { DEBUG } from "@/main";

const merchantService = new MerchantService();

export default {
  name: "Merchants",
  props: {
    headingLevel: {
      type: Number,
      default: 1
    },
    title: {
      type: String,
      default: 'Commerçants'
    },
    limit: Number,
    filterOptions: {
      type: Object,
      default: () => ({
        category: null,
        ordering: '-updated',
        page: 1
      })
    },
    showFilter: {
      type: Boolean,
      default: true
    },
    modeWidget: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: true,
      getMerchantOptions: {},
      merchantCategory: null,
      merchants: [],
      merchantCounter: 0,
      nextPage: false,
      merchantCategories: [],
      merchantOrdering: [
        { id: '-updated', name: 'Par date de modification' },
        { id: '-created', name: 'Par date d\'ajout' },
        { id: 'name', name: 'Par nom (A-Z)' },
        { id: '-name', name: 'Par nom (Z-A)' },
      ]
    };
  },
  computed: {
    merchantCategoriesUsed() {
      return this.merchantCategories.filter((cat) => cat.published === true)
    }
  },
  methods: {
    async navigate(routerName) {
      this.authStore.pageLoading = true
      this.authStore.setSelectedMenu(routerName)
      try {
        await this.$router.push({
          name: routerName,
        });
      } catch (err) {
        if (DEBUG) {
          console.error(err)
        }
      } finally {
        this.authStore.pageLoading = false
      }
    },
    async getMerchants() {
      try {
        if (this.getMerchantOptions.search === undefined) {
          const currentParam = getUrlParamsObject(window.location.href)
          let search = ''
          try {
            search = currentParam.search
          } catch (err) {
            if (DEBUG) console.error(err);
          }
          if (search) {
            this.getMerchantOptions.search = search
          }
        }

        const merchants = await merchantService.show(this.authStore.getToken, this.getMerchantOptions);
        if (this.limit) {
          return merchants.results.slice(0, this.limit);
        }
        this.merchantCounter = merchants.count
        if (merchants.next) {
          const params = getUrlParamsObject(merchants.next)
          this.getMerchantOptions.page = params.page
          this.nextPage = true
        } else {
          this.nextPage = false
        }
        return merchants.results;
      }
      catch (error) {
        if (DEBUG) {
          console.error(error)
        }
        this.$toast.error("Une erreur s'est produite lors de la récupération de la liste des commerces");
      }
      finally {
        this.loading = false;
      }
      return [];
    },
    refreshMerchants() {
      this.getMerchantOptions.page = 1
      this.getMerchants().then(merchants => this.merchants = merchants)
    },
    infiniteScroll(entries) {
      if (entries[0].isIntersecting && this.nextPage) {
        this.getMerchants().then(merchants => merchants.forEach((p) => this.merchants.push(p)))
      }
    },
    async getCategories() {
      try {
        return await merchantService.categories(this.authStore.getToken);
      }
      catch (error) {
        if (DEBUG) {
          console.log(error)
        }
        this.$toast.error("Une erreur s'est produite lors de la récupération de la liste des catégories");
      }
      return [];
    },
    getCategoryName(categoryId) {
      const categorie = this.appStore.merchantCategories.filter((cat) => cat.id === categoryId)
      if (categorie.length > 0) {
        return categorie[0].name
      }
      return ''
    }
  },
  async beforeMount() {
    this.appStore.$subscribe(async (callback, state) => {
      this.merchantCategory = state.category;
      this.merchantCategories = state.merchantCategories
      this.refreshMerchants()
      this.loading = false;
    });
    this.getMerchantOptions = this.filterOptions
    this.merchantCategory = this.appStore.category;
    this.merchantCategories = await this.getCategories()
    this.appStore.merchantCategories = this.merchantCategories
  },
  setup() {
    const authStore = useAuthStore();
    const appStore = useAppStore();
    return {
      authStore,
      appStore
    };
  },
  components: { MerchantAddForm, MerchantList, MerchantCategories }
};
</script>



