<template>
    <v-dialog max-width="500px" v-model="dialog">
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="danger" plain small v-bind="attrs" v-on="on">
                <v-icon>delete_outline</v-icon>
                <span class="sr-only">Supprimer</span>
            </v-btn>
        </template>
        <v-card>
            <v-card-title>Êtes-vous sûr de vouloir supprimer le compte administrateur "{{ admin.first_name }} {{ admin.last_name }}" ?</v-card-title>
            <v-card-text>Attention : cette action est irréversible. Le compte sera supprimé et plus récupérable.</v-card-text>
            <v-card-actions>
                <v-btn color="primary" text @click="dialog = false">Non, revenir en arrière</v-btn>

                <v-btn
                    color="danger"
                    class="ml-auto"
                    text
                    :loading="loading"
                    @click="remove()"
                >Supprimer</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { DEBUG } from "@/main";
import { AdminService } from "@/services/admin-services";
import { useAuthStore } from "@/stores/auth-store";
import { useAppStore } from "@/stores/app-store";

const adminService = new AdminService();

export default {
    name: "AdminDel",
    props: {
        admin: Object
    },
    data: () => ({
        loading: false,
        dialog: false
    }),
    methods: {
        async remove() {
            this.loading = true
            try {
                await adminService.remove(this.admin.username, this.authStore.getToken)
                this.appStore.setRefresher('delAdmin')
                this.$toast.success('Le compte a été supprimé')
                this.dialog = false
            } catch (err) {
                if (DEBUG) {
                    console.error(err)
                }
                if (Array.isArray(err)) {
                    for (const message of err) {
                        this.$toast.error(message)
                    }
                } else {
                    this.$toast.error('La suppression de l\'enregistrement a échoué. Si le problème persiste, contacter un administrateur')
                }
            } finally {
                this.loading = false
            }
        }
    },
    setup() {
        const authStore = useAuthStore();
        const appStore = useAppStore();
        return {
            authStore,
            appStore
        };
    },
}
</script>