<template>
  <div class="merchant-categories">
    <div class="shop-card">
      <div class="shop-card-container">
        <div class="categorie-title">
          <h2>
            Catégories
            <small v-if="categories.length > 0">({{ categories.length }})</small>
          </h2>
          <MerchantCategoryAddForm v-if="categories.length > 25" />
        </div>

        <template v-if="categories.length > 0">
          <ul class="categorie-list">
            <li class="categorie-list-item" v-for="category in categories" :key="category.id">
              <div class="categorie-list-status">
                <v-icon v-if="category.published" title="publié" color="green">mdi-check-circle</v-icon>
                <v-icon v-if="!category.published" title="dépublié" color="red">mdi-close-circle</v-icon>
              </div>
              <div class="categorie-list-item-text">{{ category.name }}</div>
              <img
                v-if="category.photo.square"
                class="categorie-list-photo"
                :src="category.photo.square"
                alt
              />
              <div class="categorie-list-item-action">
                <MerchantCategoryChangeForm :category="category" />
                <MerchantCategoryDel :category="category" />
              </div>
            </li>
          </ul>
          <MerchantCategoryAddForm />
        </template>
        <div class="text-center" v-if="!categories.length">
          <div class="shop-card-text">
            <div class="mb-2">
              <v-icon color="primary" x-large>list_alt</v-icon>
            </div>
            <p>
              <strong>Vous n'avez ajouté aucune catégorie de produits</strong>
            </p>
            <MerchantCategoryAddForm />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss"></style>

<script>
import MerchantCategoryDel from "@/views/merchants/components/MerchantCategoryDel.vue";
import MerchantCategoryChangeForm from "@/views/merchants/components/MerchantCategoryChangeForm.vue";
import MerchantCategoryAddForm from "@/views/merchants/components/MerchantCategoryAddForm.vue";

export default {
  name: "MerchantCategories",
  props: {
    categories: {
      type: Array,
      required: true
    },
  },
  components: { MerchantCategoryDel, MerchantCategoryChangeForm, MerchantCategoryAddForm }
};
</script>



