<template>
  <div class="admin-list">
    <div class="admin-tab-responsive">
      <table class="admin-tab">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Nom</th>
            <th scope="col">Prénom</th>
            <th scope="col">Email</th>
            <th scope="col">Actif ?</th>
            <th scope="col">
              <span class="sr-only">Action</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <Admin
            :admin="admin"
            v-for="admin, i in admins"
            :key="'admin-' + i + '-' + admin.username"
          />
        </tbody>
      </table>
    </div>
  </div>
</template>

<style lang="scss"></style>

<script>
import Admin from "@/views/admins/components/Admin.vue";

export default {
  name: "AdminList",
  props: {
    admins: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      getOptions: {},
    };
  },
  components: { Admin }
};
</script>



