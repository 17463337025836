import { VueHttp } from "@/vue-http";

export class AdminService {

  async show(token, options = null) {
    try {
      const mytoken = `token ${token}`;

      const res = await VueHttp.get(`admin/account/`, {
        headers: {
          'Authorization': mytoken,
        },
        params: options
      });

      return res.data;
    } catch (error) {
      throw error.response.data
    }
  }

  async add(data, token) {
    const mytoken = `token ${token}`;

    try {
      const res = await VueHttp.post(`admin/account/add/`, data, {
        headers: {
          'Authorization': mytoken,
        }
      });
      return res.data
    } catch (error) {
      throw error.response.data
    }
  }

  async remove(username, token) {
    const mytoken = `token ${token}`;

    try {
      const res = await VueHttp.delete(`admin/account/${username}/`, {
        headers: {
          'Authorization': mytoken,
        }
      });
      return res.data
    } catch (error) {
      throw error.response.data
    }
  }

  async update(username, data, token) {
    const mytoken = `token ${token}`;

    try {
      const res = await VueHttp.patch(`admin/account/${username}/`, data, {
        headers: {
          'Authorization': mytoken,
        }
      });
      return res.data
    } catch (error) {
      throw error.response.data
    }
  }
}