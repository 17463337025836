<template>
<div class="main-view-page">
    <PageLoader />
    <Header />
    <main id="main-content">
      <div class="container">
        <router-view :key="$route.fullPath"></router-view>
      </div>
    </main>
    <Footer />
</div>
</template>

<script>
import { AuthenticationService } from "@/services/auth-services";
import { useAuthStore } from "@/stores/auth-store";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import PageLoader from "@/components/PageLoader.vue";

const authService = new AuthenticationService();

export default {
    name: 'PrivateView',
    components: {
        Header,
        Footer,
        PageLoader
    },
    methods: {
        async getUser() {
            try {
                this.authStore.user = await authService.getUser(this.authStore.getToken);
            } catch(error) {
                return false
            }
        },
    },
    async beforeMount() {
        this.authStore.$subscribe(async (callback, state) => {
            this.user = state.user
        })
        this.getUser().catch(() => {})
    },
    setup() {
        const authStore = useAuthStore();

        return {
            authStore,
        };
    },
}
</script>