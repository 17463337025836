<template>
  <div class="dashboard-page">
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <div class="shop-card">
          <div class="shop-card-container">
            <CardStat
              v-if="stats"
              title="Administrateur"
              :stats="stats.admins"
              icon="mdi-account-tie"
              iconColor="red lighten-3"
            />
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <div class="shop-card">
          <div class="shop-card-container">
            <CardStat
              v-if="stats"
              title="Commercants"
              :stats="stats.merchants"
              icon="storefront"
              iconColor="cyan"
            />
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <div class="shop-card">
          <div class="shop-card-container">
            <CardStat
              v-if="stats"
              title="Clients"
              :stats="stats.customers"
              icon="mdi-account-circle"
              iconColor="teal"
            />
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <div class="shop-card">
          <div class="shop-card-container">
            <CardStat
              v-if="stats"
              title="Commandes"
              :stats="stats.orders"
              icon="mdi-currency-eur"
              iconColor="grey"
            />
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="shop-card">
      <l-map class="dashboard-map" :zoom="14" :center="[16.2333, -61.3833]">
        <l-tile-layer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="&copy; <a target='_blank' href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
        ></l-tile-layer>
        <template v-for="item in shopLocations">
          <l-marker
          v-if="item.latitude && item.longitude"
            :lat-lng="[item.latitude, item.longitude]"
            :key="item.id"
          >
            <l-popup>
              <div class="shop-map-popup">
                <img v-if="item.logo.square" class="shop-map-popup-logo" :src="item.logo.square" alt />
                <div class="shop-map-popup-content">
                  <div class="shop-map-popup-category">{{ item.category.name }}</div>
                  <div class="shop-map-popup-name">
                    <a :href="'/merchants?search=' + item.name">{{ item.name }}</a>
                  </div>
                </div>
              </div>
            </l-popup>
          </l-marker>
        </template>
      </l-map>
    </div>
  </div>
</template>

<style lang="scss"></style>

<script>
import { DEBUG } from "@/main";
import { MerchantService } from "@/services/merchant-services";
import { StatService } from "@/services/stat-services";
import { useAuthStore } from "@/stores/auth-store";
import CardStat from "@/views/dashboard/components/CardStat.vue";

const statService = new StatService()
const merchantService = new MerchantService

export default {
  name: "DashboardPage",
  components: {
    CardStat
  },
  data: () => ({
    stats: {},
    shopLocations: []
  }),
  methods: {
    async getStats() {
      try {
        this.stats = await statService.summary(this.authStore.getToken);
      } catch (error) {
        if (DEBUG) {
          console.error(error)
        }
      }
    },
    async getShopLocations() {
      try {
        this.shopLocations = await merchantService.locations(this.authStore.getToken);
        this.shopLocations = this.shopLocations.results
      } catch (error) {
        if (DEBUG) {
          console.error(error)
        }
      }
    },
  },
  mounted() {
    this.getStats()
    this.getShopLocations()
  },
  setup() {
    const authStore = useAuthStore();

    return {
      authStore,
    };
  },
};
</script>
