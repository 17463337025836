import { VueHttp } from "@/vue-http";

export class CustomerService {

  async show(token, options = null) {
    try {
      const mytoken = `token ${token}`;

      const res = await VueHttp.get(`admin/customer/`, {
        headers: {
          'Authorization': mytoken,
        },
        params: options
      });

      return res.data;
    } catch (error) {
      throw error.response.data
    }
  }

  async activate(username, token) {
    const mytoken = `token ${token}`;

    try {
      const res = await VueHttp.put(`admin/customer/${username}/`, {}, {
        headers: {
          'Authorization': mytoken,
        }
      });
      return res.data
    } catch (error) {
      throw error.response.data
    }
  }
}