import { VueHttp } from '@/vue-http';

export class StatService {
    async summary(token) {
        const mytoken = `token ${token}`;
        try {
            const res = await VueHttp.get(`admin/stats/summary/`, {
                headers: {
                    Authorization: mytoken
                }
            });
            return res.data;
        } catch (error) {
            throw error.message;
        }
    }
}
