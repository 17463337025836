<template>
    <tr>
        <th scope="row">{{ admin.username }}</th>
        <td>{{ admin.last_name }}</td>
        <td>{{ admin.first_name }}</td>
        <td>{{ admin.email }}</td>
        <td>
            <v-icon v-if="admin.is_active" color="green">mdi-check-circle</v-icon>
            <v-icon v-if="!admin.is_active" color="danger">mdi-close-circle</v-icon>
        </td>
        <td>
            <AdminChangeForm :admin="admin" />
            <AdminDel :admin="admin" />
        </td>
    </tr>
</template>

<script>
import { DEBUG } from "@/main";
import { useAuthStore } from "@/stores/auth-store";
import { useAppStore } from "@/stores/app-store";
import { AdminService } from "@/services/admin-services"
import AdminDel from "./AdminDel.vue";
import AdminChangeForm from "./AdminChangeForm.vue";

const adminService = new AdminService()

export default {
    name: "Admin",
    props: {
        admin: Object
    },
    data: () => ({
        loading: false,
        dialog: false,
        merchant: {
            name: ""
        }
    }),
    methods: {
        getTitle() {
            return this.admin.is_active ? "Êtes-vous sûr de vouloir désactiver ce compte ?" : "Êtes-vous sûr de vouloir activer ce compte ?";
        },
        async activate() {
            this.loading = true;
            try {
                await adminService.remove(this.admin.username, this.authStore.getToken);
                this.appStore.setRefresher("activateAdmin");
                if (this.admin.is_active) {
                    this.$toast.warning("Le compte a bien été désactivé");
                }
                else {
                    this.$toast.success("Le compte a bien été activé");
                }
                this.dialog = false;
            }
            catch (err) {
                if (DEBUG) {
                    console.error(err);
                }
                this.$toast.error("La modification de l'enregistrement a échoué. Si le problème persiste, contacter un administrateur");
            }
            finally {
                this.loading = false;
            }
        }
    },
    setup() {
        const authStore = useAuthStore();
        const appStore = useAppStore();
        return {
            authStore,
            appStore
        };
    },
    components: { AdminDel, AdminChangeForm }
}
</script>