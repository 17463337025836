<template>
    <div class="profile-page">
        <h1>
            Votre profil
            <small>{{ authStore.user.first_name }} {{ authStore.user.last_name }}</small>
        </h1>

        <v-row>
            <v-col cols="12" md="8">
                <ProfileUser />
                <ProfileEmails />
            </v-col>
            <v-col cols="12" md="4">
                <ProfilePassword />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { useAuthStore } from "@/stores/auth-store"
import ProfileUser from "@/views/profile/components/ProfileUser.vue"
import ProfileEmails from "@/views/profile/components/ProfileEmails.vue";
import ProfilePassword from "@/views/profile/components/ProfilePassword.vue";

export default {
    name: "ProfilePage",
    data: () => ({
        user: {},
    }),
    beforeMount() {
        this.user = this.authStore.user;
    },
    setup() {
        const authStore = useAuthStore();
        return {
            authStore
        };
    },
    components: { ProfileUser, ProfileEmails, ProfilePassword }
}
</script>